<template>
  <div class="pt-10">
    <div class="vx-col mx-10">
      <vs-card class="p-6">
        <div class="vx-breadcrumb md:block hidden">
          <ul class="flex flex-wrap items-center">
            <li class="inline-flex items-center">
              <router-link :to="{ name: 'regional-director-list' }"
                >Manage</router-link
              >
              <span class="breadcrumb-separator mx-2">
                <span class="feather-icon select-none relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevrons-right"
                  >
                    <polyline points="13 17 18 12 13 7" />
                  </svg>
                </span>
              </span>
            </li>
            <li class="inline-flex items-center">
              <router-link :to="{ name: 'regional-director-list' }"
                >Regional Directors</router-link
              >
              <span class="breadcrumb-separator mx-2">
                <span class="feather-icon select-none relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevrons-right"
                  >
                    <polyline points="13 17 18 12 13 7" />
                  </svg>
                </span>
              </span>
            </li>

            <li class="inline-flex items-center">
              <a href="javascript:void(0)" class>Edit Regional Director</a>
            </li>
          </ul>
        </div>
        <!-- <vs-divider class="mb-0 md:block hidden"></vs-divider> -->

        <div class="w-full flex flex-wrap ">
          <div class="md:w-1/2">
            <!-- NAME -->
            <vs-input
              label-placeholder="Regional director Name"
              v-model="name"
              class="w-full mt-5"
              name="name"
              v-validate="'required'"
              data-vv-as="Regional director Name"
            />
            <span class="text-danger text-sm" v-show="errors.has('name')">{{
              errors.first("name")
            }}</span>
            <vs-input
              label-placeholder="Phone Number"
              v-model="contactNumber"
              class="mt-5 w-full"
              name="contactNumber"
              v-validate="'required|numeric'"
              data-vv-as="phone number"
            />

            <span
              class="text-danger text-sm"
              v-show="errors.has('contactNumber')"
              >{{ errors.first("contactNumber") }}</span
            >

            <vs-input
              class="w-full mt-5"
              name="email"
              v-validate="'required|email'"
              label-placeholder="Email"
              v-model="email"
              data-vv-as="Email"
            />

            <span class="text-danger text-sm" v-show="errors.has('email')">{{
              errors.first("email")
            }}</span>
            <div class="vx-col w-full mt-5">
              <div class="select-wrapper">
                <div class="vs-component is-label-placeholder">
                  <label class="vs-input--label">Country</label>
                  <v-select
                    :options="countryOptions"
                    :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="countryFilter"
                    @input="onStateChange"
                    class="mb-4 md:mb-0"
                    data-vv-as="Country"
                  >
                    <template #header>
                      <label for class="label">Country</label>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-if="attributes" v-bind="attributes">
                        <vs-icon icon="arrow_drop_down"></vs-icon>
                      </span>
                    </template>
                  </v-select>
                  <span class="text-danger text-sm">{{ errors.first("Country") }}</span>
                </div>
              </div>
            </div>
            <div class="vx-col w-full mt-5">
              <div class="select-wrapper">
                <div class="vs-component is-label-placeholder">
                  <v-select
                    :options="stateOptions"
                    :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="stateFilter"
                    @input="onStateChange"
                    class="mb-4 md:mb-0"
                    data-vv-as="State"
                  >
                    <template #header>
                      <label for class="label">State</label>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-if="attributes" v-bind="attributes">
                        <vs-icon icon="arrow_drop_down"></vs-icon>
                      </span>
                    </template>
                  </v-select>
                  <span class="text-danger text-sm">{{ errors.first("State") }}</span>
                </div>
              </div>
            </div>
            <div vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5 ml-0">
                <input
                  type="text"
                  label-placeholder="Street Address"
                  ref="autocomplete"
                  autocomplete="off"
                  data-vv-as="Street Address"
                  name="streetAddress"
                  v-model="streetAddress"
                  class="vs-inputx vs-input--input normal"
                  :class="{ hasValue: streetAddress }"
                />
              </div>
            </div>
            <span
              class="text-danger text-sm"
              v-show="errors.has('streetAddress')"
              >{{ errors.first("streetAddress") }}</span
            >
            <vs-input
              label-placeholder="Town/City"
              v-model="town"
              class="mt-5 w-full"
              name="town"
              data-vv-as="Town"
            />
            <span class="text-danger text-sm" v-show="errors.has('town')">{{
              errors.first("town")
            }}</span>
            <vs-input
              label-placeholder="Postcode"
              v-model="postCode"
              class="mt-5 w-full"
              name="postCode"
              data-vv-as="Post Code"
            />
            <span class="text-danger text-sm" v-show="errors.has('postCode')">{{
              errors.first("postCode")
            }}</span>
          </div>

          <div class="md:w-1/2">
            <vs-table
              ref="table"
              :sst="true"
              :max-items="dataTableParams.limit"
              :data="learningCentreData"
              @search="handleSearch"
              @change-page="handleChangePage"
              :noDataText="noDataText"
              class="ml-8"
            >
              <div slot="header" class="w-full mb-8">
                <div class="flex flex-wrap items-center">
                  <div class="w-full md:w-3/4">
                    <div class="card-title">
                      <h2>Assign Centres</h2>
                    </div>
                  </div>
                </div>
                <div class="flex flex-wrap items-center mt-10 lg:mt-0">
                  <div class="w-full flex flex-wrap header-table-row">
                    <div class="w-full md:w-1/4">
                      <div class="select-wrapper md:mt-0">
                        <!-- <label class="block mb-2">Country</label> -->
                        <v-select
                          :options="countryOptions"
                          :clearable="false"
                          :dir="$vs.rtl ? 'rtl' : 'ltr'"
                          v-model="countryFilter1"
                        >
                          <template #header>
                            <label for class="label">Country</label>
                          </template>
                          <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <vs-icon icon="arrow_drop_down"></vs-icon>
                            </span>
                          </template>
                        </v-select>
                      </div>
                    </div>
                    <div class="w-full md:w-1/4 md:pl-10">
                      <div class="select-wrapper md:mt-0">
                        <v-select
                          :options="stateOptions1"
                          :clearable="false"
                          :dir="$vs.rtl ? 'rtl' : 'ltr'"
                          v-model="stateFilter1"
                          class="mb-4 lg:mb-0"
                        >
                          <template #header>
                            <label for class="label">Region</label>
                          </template>
                          <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <vs-icon icon="arrow_drop_down"></vs-icon>
                            </span>
                          </template>
                        </v-select>
                      </div>
                    </div>
                    <div class="w-full md:w-1/4 md:pl-10">
                      <vs-input
                        class="w-full search-input"
                        icon-pack="feather"
                        icon="icon-search"
                        v-model="searchQuery"
                        placeholder="Search..."
                      />
                    </div>
                  </div>

                  <div class="w-full flex flex-wrap header-table-row">
                    <b>{{ selectedCenters.length }} centres Selected</b>
                  </div>
                </div>
              </div>
              <template slot="thead">
                <vs-th>
                  <input
                    :id="'checkbox-a'"
                    type="checkbox"
                    v-model="allSelected"
                    @click="toggleAllCenters($event)"
                  />
                </vs-th>
                <vs-th>Name</vs-th>
                <vs-th>Country</vs-th>
                <vs-th>Region</vs-th>
                <vs-th>No. Regional Director/s</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr
                  :data="tr"
                  :key="indextr"
                  v-for="(tr, indextr) in data"
                  @click.stop="viewHandler(tr._id)"
                >
                  <template>
                    <vs-td>
                      <input
                        :id="'checkbox-' + tr._id"
                        name="selectedCenters"
                        type="checkbox"
                        v-model="selectedCenters"
                        :value="tr._id"
                        v-on:change="handleCheckBoxChange"
                      />
                    </vs-td>
                    <vs-td :data="tr.name">
                      {{ tr.name | capitalize }}
                    </vs-td>

                    <vs-td :data="tr.country ? tr.country[0] : ''">
                      {{ tr.country ? tr.country[0] : "" | capitalize }}
                    </vs-td>
                    <vs-td :data="tr.address.state">
                      {{ tr.address.state | capitalize }}
                    </vs-td>
                    <vs-td :data="tr.regionalDirectors">
                      {{
                        tr.regionalDirectors.length == 0
                          ? "Unassigned"
                          : tr.regionalDirectors.length
                      }}
                    </vs-td>
                  </template>
                </vs-tr>
              </template>
            </vs-table>
            <div class="flex flex-wrap items-center pt-6 justify-end">
              <vs-button color="danger" @click="cancelBtn">Cancel</vs-button>
              <vs-button
                class="ml-8"
                @click="submitData"
                :disabled="!isFormValid"
                >Save</vs-button
              >
            </div>
          </div>
        </div>
      </vs-card>
    </div>
  </div>
</template>
<script
  type="text/javascript"
  async
  src="https://maps.googleapis.com/maps/api/js?key=AIzaSyD2_dLcglU5iyLUQ9hYiUuvpAyy7iRT3-o&libraries=places"
></script>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import _ from "lodash";
import { loaded } from "vue2-google-maps";

export default {
  data() {
    return {
      selectedCenters: [],
      centerIds: [],
      dataId: null,
      directorId: "",
      name: "",
      contactNumber: "",
      fullName: "",
      email: "",
      allSelected: false,
      logo: null,
      image: null,
      settings: {
        // perfect scrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      streetAddress: "",
      town: "",
      postCode: "",
      countryOptions: [],
      stateOptions: [],
      ageOptions: [],
      countryFilter: { label: "All", value: "all" },
      stateFilter: { label: "All", value: "all" },
      ageFilter: { label: "Select age group", value: "" },
      customError: "",
      address: {
        displayAddress: "",
        suburb: "",
        state: "",
        street: "",
        streetNumber: "",
        postCode: "",
        location: {
          type: "point",
          coordinates: []
        },
        addressData: []
      },
      checkDisplayAddress: "",
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "asc",
        limit: 100,
        page: 1,
        country: "all",
        state: "all"
      },
      learningCentreData: [],
      countryFilter1: { label: "All", value: "all" },
      stateOptions1: [],
      stateFilter1: { label: "All", value: "all" },
      searchQuery: "",
      noDataText: "Currently no learning center available",
      awaitingSearch: false,
      directorDetails: {}
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    isFormValid() {
      return (
        !this.errors.any() && this.name && this.contactNumber && this.email
      );
    },
    director() {
      return this.$store.state.AppActiveUser;
    }
  },
  async mounted() {
    //  console.log( this.google );
    this.$gmapApiPromiseLazy().then(async () => {
      this.autocomplete = new google.maps.places.Autocomplete(
        this.$refs.autocomplete,
        { types: ["geocode"] }
      );
      // , componentRestrictions: {country: "au"}
      this.autocomplete.addListener("place_changed", () => {
        let place = this.autocomplete.getPlace();
        this.errors.remove("streetAddress");
        let ac = place.address_components;
        this.address.addressData = place.address_components;
        this.address.addressData[0].checkDisplayAddress =
          place.formatted_address;
        this.checkDisplayAddress = place.formatted_address;
        let lat = place.geometry.location.lat();
        let lon = place.geometry.location.lng();
        this.address.displayAddress = place.formatted_address;
        this.streetAddress = place.formatted_address;
        this.address.location.coordinates[0] = lon;
        this.address.location.coordinates[1] = lat;
      });
    });
  },
  methods: {
    ...mapActions("director", [
      "getDirectorAllCountries",
      "updateRegionalDirector",
      "getRegionalDirectorDetail"
    ]),
    ...mapActions("center", [
      "getDirectorLearningCentreList",
      "getDirectorCenterById"
    ]),
    toggleAllCenters(event) {
      if (event.target.checked) {
        this.selectedCenters = this.centerIds;
      } else {
        this.selectedCenters = [];
      }
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getLearningCentreData();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getLearningCentreData();
    },
    handleSelected(tr) {
      this.viewHandler(tr._id);
    },
    handleCheckBoxChange(){
      if(_.isEmpty(_.xor(this.selectedCenters, this.centerIds))) {
        this.allSelected = true
      } else {
          this.allSelected = false
        };
    },
    async getLearningCentreData() {
      this.dataTableParams.country =
        this.countryFilter1.value == "all" ? "" : this.countryFilter1.value;
      this.dataTableParams.state =
        this.stateFilter1.value == "all" ? "" : this.stateFilter1.value;
      this.dataTableParams.search = this.searchQuery;
      this.dataTableParams.directorId = this.director.id;
      this.$vs.loading();

      await this.getDirectorLearningCentreList(this.dataTableParams)
        .then(async res => {
          let centers = await res.data.data;

          this.learningCentreData = centers.docs;
          let self = this;
          this.learningCentreData.map(function(value, key) {
            self.centerIds.push(value._id);
          });
          if(_.isEmpty(_.xor(this.selectedCenters, self.centerIds))) this.allSelected = true;
          this.serverResponded = true;
          this.$vs.loading.close();
        })
        .catch(err => {
          this.$vs.loading.close();
          if (err.response.status === 403) {
            Swal.fire({
              title: "No Access",
              html: `
                  <div>
                    You dont have permission to visit this page. Please upgrade your package.
                  </div>`,
              icon: "warning",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33"
            }).then(result => {
              this.$router.push("/director/upgrade-package");
            });
          }
        });
    },
    place_changed(a) {},
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        var reader = new FileReader();
        reader.onload = e => {
          this.logo = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    onStateChange() {
      this.errors.clear();
    },
    async submitData() {
      await this.$validator.validateAll().then(async result => {
        if (result) {
          let sA = this.streetAddress.split(",");
          if (this.countryFilter.value == "all") {
            this.errors.add({
                  field: "Country",
                  msg: "Country cant be 'All'"
            });
          return;
          }
          if (this.stateFilter.value == "all") {
            this.errors.add({
                  field: "State",
                  msg: "State cant be 'All'"
            });
          return;
          }

          let obj = {
            id: this.dataId,
            name: this.name,
            contactNumber: this.contactNumber,
            directorId: this.director.id,
            groupId: this.director.id,
            email: this.email,
            country: this.countryFilter.value == "all" ? "":this.countryFilter.value,
            state: this.stateFilter.value == "all" ? "":this.stateFilter.value,
            displayAddress: this.streetAddress,
            regionalDirectorLearningCenters: this.selectedCenters,
            streetAddress: sA[0],
            town: this.town,
            postCode: this.postCode,
            location: JSON.stringify(this.address.location)
          };

          this.customError = "";
          if (this.dataId !== null) {
            this.$vs.loading();

            await this.updateRegionalDirector(obj)
              .then(res => {
                if (res.status === 200) {
                  this.showMessage(
                    "Success",
                    "Centre Updated successfully.",
                    "success"
                  );
                  data.country = this.countryFilter.value;
                  // this.$emit("refreshData", true);
                }
                this.$vs.loading.close();
                this.$router.push({ name: "regional-director-list" });
              })
              .catch(err => {
                this.$vs.loading.close();
              });
          }
        }
      });
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    cancelBtn() {
      this.$router.push({
        name: "regional-director-view",
        params: { id: this.$route.params.id }
      });
    }
  },
  async created() {
    await this.getDirectorAllCountries().then(res => {
      this.countryOptions = this.$store.state.director.countryOptions;
      this.countryOptions1 = this.$store.state.director.countryOptions;
    });

    this.dataId = this.$route.params.id;
    await this.getRegionalDirectorDetail(this.dataId)
      .then(async res => {
        if (res.status === 200) {
          this.directorDetails = await res.data.data;
          let result = this.directorDetails;

          this.name = result.fullName;
          this.contactNumber = result.mobileNumber;
          this.email = result.email;

          this.countryFilter = {
            label: result.country.name,
            value: result.country._id
          };
          this.stateFilter = {
            label: result.address.state,
            value: result.address.state
          };

          this.town = result.address.suburb;
          this.postCode = result.address.postCode;
          this.streetAddress = result.address.displayAddress;
          this.selectedCenters = result.regionalDirectorLearningCenters;
          // this.town = result._id;
        }
      })
      .catch(err => {
        Swal.fire({
          title: "aaerror",
          html: `
                <div>
                  Something went wrong.
                </div>`,
          icon: "warning",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33"
        });
      });

    await this.getLearningCentreData();
  },
  watch: {
    countryFilter(obj) {
      this.stateOptions = obj.states;
    },

    countryFilter1(obj) {
      console.log("sss", obj);
      this.stateOptions1 = obj.states;
      this.stateFilter1 = { label: "All", value: "all" };
      this.getLearningCentreData();
    },
    stateFilter1(obj) {
      this.getLearningCentreData();
    },
    searchQuery: function(val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.getLearningCentreData();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    }
  },
  components: {
    vSelect
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
